import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import DateField from '@/shared/fields/date-field';
import { ProjectField } from '@/modules/project/project-field';

import { FacultyField } from '@/modules/faculty/faculty-field';

function label(name) {
  return i18n(`entities.projectReleaseRequest.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.projectReleaseRequest.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  projectID: new ProjectField.relationToOne('projectID', label('projectID'), {}),
  faculty: FacultyField.relationToOne('faculty', label('faculty'), {}),
  decision: new EnumeratorField('decision', label('decision'), [
    { id: 'approved', label: enumeratorLabel('decision', 'approved') },
    { id: 'rejected', label: enumeratorLabel('decision', 'rejected') },
    { id: 'revise', label: enumeratorLabel('decision', 'revise') },
  ], {
    required: true
  }),
  date: new DateField('date', label('date'), {
    required: true,
  }),
  comment: new StringField('comment', label('comment'), {
    "max": 255,
    "required": true
  }),
  status: new EnumeratorField('status', label('status'), [   
    { id: 'requested', label: enumeratorLabel('status', 'requested') },
    { id: 'processed', label: enumeratorLabel('status', 'processed') },
    { id: 'revisionRequested', label: enumeratorLabel('status', 'revisionRequested') },
    { id: 'deprecated', label: enumeratorLabel('status', 'deprecated') },
  ], {}),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),

};

export class ProjectReleaseRequestModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
