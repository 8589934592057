<template>
  <div>
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      v-if="model"
      style="padding: 20px"
    >
      <el-form-item
        :label="fields.decision.label"
        :prop="fields.decision.name"
        :required="fields.decision.required"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <el-select
            placeholder
            v-model="model[fields.decision.name]"
          >
            <el-option :value="undefined">--</el-option>
            <el-option
              :key="option.id"
              :label="option.label"
              :value="option.id"
              v-for="option in fields.decision.options"
            ></el-option>
          </el-select>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.comment.label"
        :prop="fields.comment.name"
        :required="fields.comment.required"
        v-if="isCommentRequired"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <el-input
            :rows="4"
            type="textarea"
            v-model="model[fields.comment.name]"
          />
        </el-col>
      </el-form-item>

      <el-form-item v-if="isRejected">
        <el-tag type="warning">
          {{ i18n('entities.projectReleaseRequest.update.warning') }}
        </el-tag>
      </el-form-item>

      <el-form-item
        :label="fields.date.label"
        :prop="fields.date.name"
        :required="fields.date.required"
      >
        <el-col :lg="13" :md="16" :sm="24">
          <el-date-picker
            :disabled="!isAdmin"
            :format="elementUiDateFormat"
            placeholder
            type="date"
            v-model="model[fields.date.name]"
          ></el-date-picker>
        </el-col>
      </el-form-item>

      <el-form-item>
        <div class="form-buttons">
          <el-button
            :disabled="saveLoading"
            @click="doSubmit"
            icon="el-icon-fa-floppy-o"
            type="primary"
          >
            <app-i18n code="common.save"></app-i18n>
          </el-button>
          <el-button
            :disabled="saveLoading"
            @click="doCancel"
            icon="el-icon-fa-close"
          >
            <app-i18n code="common.cancel"></app-i18n>
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ProjectReleaseRequestModel } from '@/modules/project-release-request/project-release-request-model';
import moment from 'moment';
import { i18n } from '@/i18n';

const { fields } = ProjectReleaseRequestModel;
const formSchema = new FormSchema([
  fields.id,
  fields.projectID,
  fields.faculty,
  fields.decision,
  fields.date,
  fields.comment,
  fields.updatedAt,
]);

export default {
  name: 'app-project-release-request-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
    this.model.date = this.today();
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
      isAdmin: 'auth/isAdmin',
    }),

    fields() {
      return fields;
    },

    isCommentRequired() {
      return (
        this.model.decision === 'rejected' ||
        this.model.decision === 'revise'
      );
    },

    isRejected() {
      return this.model.decision === 'rejected';
    },
  },

  watch: {
    record: function () {
      this.doUpdateModel();
    },
  },

  methods: {
    today() {
      return moment(Date.now());
    },

    i18n(string) {
      return i18n(string);
    },

    getFormat() {},

    doUpdateModel() {
      this.model = formSchema.initialValues(this.record);
    },

    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },
  },
};
</script>

<style>
</style>
